// import "core-js/stable";
// import "regenerator-runtime/runtime";
import React from "react";
import { StyleProvider } from "@ant-design/cssinjs";
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "antd/dist/reset.css";
import "./src/css/tailwind.scss";
import "./src/components/Layout/layout.scss";
import "./src/css/default.scss";

export const wrapRootElement = ({ element }) => {
    return <StyleProvider hashPriority="low">{element}</StyleProvider>;
};

exports.components = {
  "component---src-components-templates-category-tsx": () => import("./../../../src/components/Templates/category.tsx" /* webpackChunkName: "component---src-components-templates-category-tsx" */),
  "component---src-components-templates-elements-tsx": () => import("./../../../src/components/Templates/elements.tsx" /* webpackChunkName: "component---src-components-templates-elements-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calculators-asset-value-tsx": () => import("./../../../src/pages/calculators/asset-value.tsx" /* webpackChunkName: "component---src-pages-calculators-asset-value-tsx" */),
  "component---src-pages-calculators-bmi-tsx": () => import("./../../../src/pages/calculators/bmi.tsx" /* webpackChunkName: "component---src-pages-calculators-bmi-tsx" */),
  "component---src-pages-calculators-loan-tsx": () => import("./../../../src/pages/calculators/loan.tsx" /* webpackChunkName: "component---src-pages-calculators-loan-tsx" */),
  "component---src-pages-calculators-mortgage-tsx": () => import("./../../../src/pages/calculators/mortgage.tsx" /* webpackChunkName: "component---src-pages-calculators-mortgage-tsx" */),
  "component---src-pages-calculators-personal-net-worth-tsx": () => import("./../../../src/pages/calculators/personal-net-worth.tsx" /* webpackChunkName: "component---src-pages-calculators-personal-net-worth-tsx" */),
  "component---src-pages-calculators-retirement-savings-tsx": () => import("./../../../src/pages/calculators/retirement-savings.tsx" /* webpackChunkName: "component---src-pages-calculators-retirement-savings-tsx" */),
  "component---src-pages-calculators-sars-income-tax-tsx": () => import("./../../../src/pages/calculators/sars-income-tax.tsx" /* webpackChunkName: "component---src-pages-calculators-sars-income-tax-tsx" */),
  "component---src-pages-calculators-tip-tsx": () => import("./../../../src/pages/calculators/tip.tsx" /* webpackChunkName: "component---src-pages-calculators-tip-tsx" */),
  "component---src-pages-calculators-travel-expense-tsx": () => import("./../../../src/pages/calculators/travel-expense.tsx" /* webpackChunkName: "component---src-pages-calculators-travel-expense-tsx" */),
  "component---src-pages-chemistry-periodic-table-tsx": () => import("./../../../src/pages/chemistry/periodic-table.tsx" /* webpackChunkName: "component---src-pages-chemistry-periodic-table-tsx" */),
  "component---src-pages-converters-currency-converter-tsx": () => import("./../../../src/pages/converters/currency-converter.tsx" /* webpackChunkName: "component---src-pages-converters-currency-converter-tsx" */),
  "component---src-pages-converters-unit-converter-tsx": () => import("./../../../src/pages/converters/unit-converter.tsx" /* webpackChunkName: "component---src-pages-converters-unit-converter-tsx" */),
  "component---src-pages-dates-add-days-tsx": () => import("./../../../src/pages/dates/add-days.tsx" /* webpackChunkName: "component---src-pages-dates-add-days-tsx" */),
  "component---src-pages-dates-subtract-days-tsx": () => import("./../../../src/pages/dates/subtract-days.tsx" /* webpackChunkName: "component---src-pages-dates-subtract-days-tsx" */),
  "component---src-pages-dates-timer-tsx": () => import("./../../../src/pages/dates/timer.tsx" /* webpackChunkName: "component---src-pages-dates-timer-tsx" */),
  "component---src-pages-dictionary-tsx": () => import("./../../../src/pages/dictionary.tsx" /* webpackChunkName: "component---src-pages-dictionary-tsx" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor.tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */),
  "component---src-pages-images-image-to-svg-tsx": () => import("./../../../src/pages/images/image-to-svg.tsx" /* webpackChunkName: "component---src-pages-images-image-to-svg-tsx" */),
  "component---src-pages-images-image-to-webp-tsx": () => import("./../../../src/pages/images/image-to-webp.tsx" /* webpackChunkName: "component---src-pages-images-image-to-webp-tsx" */),
  "component---src-pages-images-jpg-to-png-tsx": () => import("./../../../src/pages/images/jpg-to-png.tsx" /* webpackChunkName: "component---src-pages-images-jpg-to-png-tsx" */),
  "component---src-pages-images-pdf-to-images-tsx": () => import("./../../../src/pages/images/pdf-to-images.tsx" /* webpackChunkName: "component---src-pages-images-pdf-to-images-tsx" */),
  "component---src-pages-images-png-to-jpg-tsx": () => import("./../../../src/pages/images/png-to-jpg.tsx" /* webpackChunkName: "component---src-pages-images-png-to-jpg-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-tsx": () => import("./../../../src/pages/invoice.tsx" /* webpackChunkName: "component---src-pages-invoice-tsx" */),
  "component---src-pages-maths-linear-equation-solver-tsx": () => import("./../../../src/pages/maths/linear-equation-solver.tsx" /* webpackChunkName: "component---src-pages-maths-linear-equation-solver-tsx" */),
  "component---src-pages-maths-quadratic-equation-solver-tsx": () => import("./../../../src/pages/maths/quadratic-equation-solver.tsx" /* webpackChunkName: "component---src-pages-maths-quadratic-equation-solver-tsx" */),
  "component---src-pages-ocr-tsx": () => import("./../../../src/pages/ocr.tsx" /* webpackChunkName: "component---src-pages-ocr-tsx" */),
  "component---src-pages-pdf-edit-pdf-tsx": () => import("./../../../src/pages/pdf/edit-pdf.tsx" /* webpackChunkName: "component---src-pages-pdf-edit-pdf-tsx" */),
  "component---src-pages-pdf-pdf-splits-tsx": () => import("./../../../src/pages/pdf/pdf-splits.tsx" /* webpackChunkName: "component---src-pages-pdf-pdf-splits-tsx" */),
  "component---src-pages-pdf-sign-a-pdf-tsx": () => import("./../../../src/pages/pdf/sign-a-pdf.tsx" /* webpackChunkName: "component---src-pages-pdf-sign-a-pdf-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-qr-tsx": () => import("./../../../src/pages/qr.tsx" /* webpackChunkName: "component---src-pages-qr-tsx" */),
  "component---src-pages-sentiment-analysis-tsx": () => import("./../../../src/pages/sentiment-analysis.tsx" /* webpackChunkName: "component---src-pages-sentiment-analysis-tsx" */),
  "component---src-pages-summarizer-tsx": () => import("./../../../src/pages/summarizer.tsx" /* webpackChunkName: "component---src-pages-summarizer-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-wheelofnames-tsx": () => import("./../../../src/pages/wheelofnames.tsx" /* webpackChunkName: "component---src-pages-wheelofnames-tsx" */),
  "component---src-pages-words-combiner-tsx": () => import("./../../../src/pages/words/combiner.tsx" /* webpackChunkName: "component---src-pages-words-combiner-tsx" */),
  "component---src-pages-words-counter-tsx": () => import("./../../../src/pages/words/counter.tsx" /* webpackChunkName: "component---src-pages-words-counter-tsx" */),
  "component---src-pages-words-password-generator-tsx": () => import("./../../../src/pages/words/password-generator.tsx" /* webpackChunkName: "component---src-pages-words-password-generator-tsx" */),
  "component---src-pages-words-text-equality-tsx": () => import("./../../../src/pages/words/text-equality.tsx" /* webpackChunkName: "component---src-pages-words-text-equality-tsx" */),
  "component---src-pages-youtube-youtube-video-to-song-tsx": () => import("./../../../src/pages/youtube/youtube-video-to-song.tsx" /* webpackChunkName: "component---src-pages-youtube-youtube-video-to-song-tsx" */)
}

